import React from 'react'
import { graphql, navigate } from 'gatsby'
import Layout from '../../components/Layout'
import TeLlamamosContent from '../../components/TeLlamamos/TeLlamamosContent'
import { SeoData } from '../../../../src/common/types/Seo'
import route from '../../utils/route'

interface Props {
  data: {
    seoData: SeoData
    imagen_ca_desktop: {
      nodes: { key: string; url: string }[]
    }
    imagen_es_desktop: {
      nodes: { key: string; url: string }[]
    }
    imagen_ca_mobile: {
      nodes: { key: string; url: string }[]
    }
    imagen_es_mobile: {
      nodes: { key: string; url: string }[]
    }
    imagen_pt_desktop: {
      nodes: { key: string; url: string }[]
    }
    imagen_pt_mobile: {
      nodes: { key: string; url: string }[]
    }
  }
}

export default function TeLlamamos({ data }: Props) {
  return (
    <Layout selector={true} seoData={data.seoData} hideTeLlamamos={true}>
      <TeLlamamosContent data={data} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query AreaPrivadaQuery2($url: String!) {
    seoData: pageInfo(url: { eq: $url }) {
      ...seoinformation
    }

    imagen_ca_desktop: allS3Asset(
      filter: { Key: { glob: "te-llamamos/banners/ca/desktop/*" } }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    imagen_ca_mobile: allS3Asset(
      filter: { Key: { glob: "te-llamamos/banners/ca/mobile/*" } }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    imagen_es_desktop: allS3Asset(
      filter: { Key: { glob: "te-llamamos/banners/es/desktop/*" } }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    imagen_es_mobile: allS3Asset(
      filter: { Key: { glob: "te-llamamos/banners/es/mobile/*" } }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    imagen_pt_desktop: allS3Asset(
      filter: { Key: { glob: "area_privada/banners/desktop/*" } }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    imagen_pt_mobile: allS3Asset(
      filter: { Key: { glob: "area_privada/banners/mobile/*" } }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
  }
`
