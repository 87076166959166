import React, { useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import SpinnerWithText from '../spinnerWithText/SpinnerWithText'
import styles from './teLlamamosContent.module.scss'
import ProteccionDatos from '../TeLlamamos/ProteccionDatos'
import TeLlamamosForm from '../TeLlamamos/TeLlamamosForm'
import ResponsiveSliderBanner from '../ResponsiveSliderBanner/ResponsiveSliderBanner'
import { locale, t } from '../../i18n'
import { getImages } from '../../utils/imagesS3'
import AtencionAlCliente from '../funnelSideBar/AtencionAlCliente'
import CopyRightFooter from '../footers/CopyRightFooter/CopyRightFooter'
import { SeoData } from '../../types/Seo'
import dataLayer from '../../helpers/functions/dataLayer'

interface Props {
  data: {
    seoData: SeoData
    imagen_ca_desktop: {
      nodes: { key: string; url: string }[]
    }
    imagen_es_desktop: {
      nodes: { key: string; url: string }[]
    }
    imagen_ca_mobile: {
      nodes: { key: string; url: string }[]
    }
    imagen_es_mobile: {
      nodes: { key: string; url: string }[]
    }
    imagen_pt_desktop: {
      nodes: { key: string; url: string }[]
    }
    imagen_pt_mobile: {
      nodes: { key: string; url: string }[]
    }
  }
}

const placeholderImage = {
  Key: '',
  url: 'https://via.placeholder.com/800x200?text=Placeholder+Image',
  link: '',
  alt: '',
}

const TeLlamamosContent = ({ data }: Props) => {
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)

  useEffect(() => {
    dataLayer.push({
      event: 'callback',
      action: 'conversion',
    })
  }, [success])

  const banners_mobile = getImages(
    data[`imagen_${locale}_mobile`].nodes,
    locale,
    placeholderImage
  )
  const banners_desktop = getImages(
    data[`imagen_${locale}_desktop`].nodes,
    locale,
    placeholderImage
  )

  return (
    <>
      <div className={styles.container}>
        <div className={styles.main_container}>
          <div className={styles.te_llamamos_container}>
            {/* Title */}
            <div className={styles.title_container}>
              <h1 className={styles.title}>{t('funnel.te_llamamos_gratis')}</h1>
            </div>
            {/* Spinner loading */}
            {loading && <SpinnerWithText text="Cargando..." />}
            {/* Form and times */}
            <TeLlamamosForm setLoading={setLoading} setSuccess={setSuccess} />
            {/* Confirmation call */}
            {!loading && success && (
              <div className={styles.wrapper_step2}>
                <div className={styles.color_text}>
                  <FormattedMessage id="funnel.gracias" />
                </div>
                <div className={styles.process_text}>
                  <FormattedMessage id="funnel.contacto" />
                </div>
                <div className={styles.button_wrapper}></div>
              </div>
            )}
          </div>
          {/* Banner */}
          <div>
            <ResponsiveSliderBanner
              desktopImages={banners_desktop}
              mobileImages={banners_mobile}
            />
          </div>
          {/* Proteccion Datos message */}
          <ProteccionDatos />
        </div>
        {/* Aside Atención al Cliente */}
        <div className={styles.atencion_al_cliente}>
          <AtencionAlCliente />
        </div>
      </div>
      {/* Footer */}
      <div className={styles.footer}>
        <CopyRightFooter />
      </div>
    </>
  )
}

export default TeLlamamosContent
